import { useNavigate, useSearchParams } from "react-router-dom";
import { SetPasswordDto, SetPasswordSchema } from "../contracts/schemas";
import { useEffect, useState } from "react";
import { setPasswordAsync, verifySignedLinkAsync } from "../lib/api";
import { ErrorV1, SetPasswordRequestV1 } from "../contracts/api";
import { HeaderLayoutCenter } from "../components/HeaderLayoutCenter";
import {
  SetPasswordSignedLinkParameters,
  ValidateSignedLink,
} from "../lib/helpers";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Checkbox,
  Link,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { TermsModal } from "../components/migration/TermsModal";
import getEnv from "../env/environment";
import useProgressStore from "../store/progressStore";
import Loader from "../components/Loader";
import { trackEvent } from "../utils/analytics";

export default function SetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [linkValidated, setLinkValidated] = useState(false);
  const [slp, setSlp] = useState({} as SetPasswordSignedLinkParameters);
  const [passwordAlreadyUsed, setPasswordAlreadyUsed] = useState(false);
  const [linkVerified, setLinkVerified] = useState(false);
  const { setProgress } = useProgressStore();

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors, isSubmitting },
    setValue,
  } = useForm<SetPasswordDto>({
    values: {
      termsVersion: getEnv().CURRENT_TERMS_VERSION,
    } as SetPasswordDto,
    mode: "onChange",
    resolver: yupResolver(SetPasswordSchema),
  });

  useEffect(() => {
    document.title = "Set password | TheGuarantors";
    watch(() => setPasswordAlreadyUsed(false));
  }, [watch]);

  useEffect(() => {
    trackEvent("Set Password page Loaded", {
      referrer: window.location.pathname,
    });
  }, []);

  useEffect(() => {
    if (linkValidated) return;

    const result =
      ValidateSignedLink<SetPasswordSignedLinkParameters>(searchParams);
    if (!result.Success) {
      if (result.Error!.message === "SIGNED_LINK_EXPIRED") {
        navigate("/error", {
          state: { message: "SET_PASSWORD_SIGNED_LINK_EXPIRED" } as ErrorV1,
        });
        return;
      } else if (result.Error!.message === "SIGNED_LINK_USED") {
        navigate("/error", {
          state: { message: "SET_PASSWORD_SIGNED_LINK_USED" } as ErrorV1,
        });
        return;
      }
      navigate("/error", { state: result.Error! });
      return;
    }

    const signedLinkParameters = result.Value!;

    if (
      signedLinkParameters.redirect_uri === undefined ||
      signedLinkParameters.user_id === undefined
    ) {
      navigate("/error", {
        state: { message: "SIGNED_LINK_INVALID" } as ErrorV1,
      });
      return;
    }

    (async () => {
      const result = await verifySignedLinkAsync(window.location.href);
      setLinkVerified(true);

      if (result.Success) {
        return;
      }

      if (result.Error!.message === "SIGNED_LINK_EXPIRED") {
        navigate("/error", {
          state: { message: "SET_PASSWORD_SIGNED_LINK_EXPIRED" } as ErrorV1,
        });
        return;
      } else if (result.Error!.message === "SIGNED_LINK_USED") {
        navigate("/error", {
          state: { message: "SET_PASSWORD_SIGNED_LINK_USED" } as ErrorV1,
        });
        return;
      }

      navigate("/error", {
        state: result.Error,
      });
      return;
    })();

    setLinkValidated(true);

    setSlp(signedLinkParameters);

    setValue("acceptedTerms", !signedLinkParameters.terms);
  }, [navigate, searchParams, linkValidated, setValue]);

  const isPasswordHistoryError = (error: ErrorV1): boolean =>
    error.StatusCode === 400 &&
    (error.message ===
      "Bad Request: PasswordHistoryError: Password has previously been used" ||
      (error.message === "Bad Request" &&
        error.validation_errors !== undefined &&
        error.validation_errors[0] === "'password' has previously been used"));

  useEffect(() => {
    setProgress(50);
  }, [setProgress]);

  const [showTerms, setShowTerms] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const submit = (data: SetPasswordDto) => {
    (async () => {
      const request: SetPasswordRequestV1 = {
        password: data.password,
        terms_version: slp.terms ? data.termsVersion : undefined,
      };

      const result = await setPasswordAsync(window.location.href, request);

      if (!result.Success) {
        trackEvent("Introduce your password", {
          error: true,
        });

        if (isPasswordHistoryError(result.Error!)) {
          setPasswordAlreadyUsed(true);
          return;
        }

        console.error(result.Error);

        if (result.Error!.message === "SIGNED_LINK_EXPIRED") {
          navigate("/error", {
            state: { message: "SET_PASSWORD_SIGNED_LINK_EXPIRED" } as ErrorV1,
          });
          return;
        } else if (result.Error!.message === "SIGNED_LINK_USED") {
          navigate("/error", {
            state: { message: "SET_PASSWORD_SIGNED_LINK_USED" } as ErrorV1,
          });
          return;
        }

        navigate("/error", { state: result.Error });
        return;
      }

      trackEvent("Introduce your password", {
        error: false,
      });

      window.location.assign(result.Value!.redirect_uri);
    })();
  };

  if (!linkVerified) {
    return <Loader />;
  }

  return (
    <HeaderLayoutCenter
      title="Set your password"
      subtitle={`Enter and confirm your password to secure your account.`}
      maxWidth={500}
      children={
        <form className="form" onSubmit={handleSubmit(submit)}>
          <Box>
            <TextField
              {...register("password")}
              label="Enter Password"
              type={showPassword1 ? "text" : "password"}
              error={!!errors.password}
              helperText={errors?.password?.message ?? " "}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword1}
                      onMouseDown={handleMouseDownPassword1}
                    >
                      {showPassword1 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  root: "fs-exclude",
                },
              }}
            />
            <TextField
              {...register("confirmPassword")}
              label="Confirm Password"
              type={showPassword2 ? "text" : "password"}
              error={!!errors.confirmPassword}
              helperText={errors?.confirmPassword?.message ?? " "}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword2}
                    >
                      {showPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  root: "fs-exclude",
                },
              }}
            />
            {passwordAlreadyUsed && (
              <Typography
                data-testid={"password-error-2"}
                sx={{
                  color: "#ff3030",
                  textAlign: "center",
                }}
              >
                This password has been used previously, please enter a new one.
              </Typography>
            )}
            {slp.terms && (
              <Box>
                <Checkbox {...register("acceptedTerms")} />
                <Typography display="inline">
                  I agree to the{" "}
                  <Link id="TermsLink" onClick={() => setShowTerms(true)}>
                    Terms & Conditions.
                  </Link>
                </Typography>
              </Box>
            )}
            <LoadingButton
              variant="contained"
              type="submit"
              color="primary"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              sx={{ mt: 2, marginX: "auto", display: "block" }}
            >
              Submit
            </LoadingButton>
          </Box>
          {slp.terms && (
            <TermsModal modal={showTerms} setModal={setShowTerms} />
          )}
        </form>
      }
    />
  );
}
