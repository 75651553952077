import { Auth0Provider } from "@auth0/auth0-react";
import ReactDOM from "react-dom/client";
import getEnv from "./env/environment";
import "./index.css";
import App from "./pages/App";
import { materialTheme } from "./theme";
import { ThemeProvider as MuiTheme } from "@mui/material/styles";
import { datadogLogs } from "@datadog/browser-logs";
import { OnRedirectCallback } from "./pages/auth0/HandleCallback";

if (getEnv().CONTAINER_MODE) {
  Object.defineProperty(window, "crypto", {
    value: { subtle: {} },
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

datadogLogs.init({
  clientToken: getEnv().DD_CLIENT_ID,
  service: "tg-login-ui",
  env: getEnv().DD_ENV,
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  forwardConsoleLogs: "all",
  sessionSampleRate: 100,
});

root.render(
  <Auth0Provider
    domain={getEnv().AUTH0_DOMAIN}
    clientId={getEnv().AUTH0_CLIENT_ID}
    onRedirectCallback={OnRedirectCallback}
    useRefreshTokens={true}
    cacheLocation={"localstorage"}
    authorizationParams={{
      redirect_uri: getEnv().AUTH0_REDIRECT_URI,
      scope: getEnv().AUTH0_SCOPES,
    }}
  >
    <MuiTheme theme={materialTheme}>
      <App />
    </MuiTheme>
  </Auth0Provider>,
);
