import OktaAuth from "@okta/okta-auth-js";
import getEnv from "../../env/environment";

export const InitOktaClient = () => {
  return new OktaAuth({
    issuer: getEnv().OKTA_ISSUER_URL,
    clientId: getEnv().OKTA_CLIENT_ID,
    redirectUri: getEnv().OKTA_CALLBACK_URL,
    scopes: ["openid", "profile", "email", "groups"],
    pkce: true,
  });
};

export const InitOktaMsaClient = () => {
  return new OktaAuth({
    issuer: getEnv().OKTA_ISSUER_URL,
    clientId: getEnv().OKTA_MSA_CLIENT_ID,
    redirectUri: getEnv().OKTA_MSA_CALLBACK_URL,
    scopes: ["openid", "profile", "email", "groups"],
    pkce: true,
  });
};
