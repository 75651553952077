import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { routes } from "../../constants/Constants";
import { InitOktaClient } from "./OktaClient";
import Loader from "../../components/Loader";

export default function HandleOktaSignIn() {
  const oktaAuth = InitOktaClient();

  const login = async () => oktaAuth.signInWithRedirect();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const initOktaAuth = async () => {
      if (isAuthenticated) return;
      setIsAuthenticated(await oktaAuth.isAuthenticated());
    };

    initOktaAuth();
  }, [oktaAuth, setIsAuthenticated, isAuthenticated]);

  if (isAuthenticated) {
    navigate(routes.oktaToken);
  } else {
    login();
  }

  return <Loader />;
}
