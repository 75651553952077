import { type FC } from "react";
import TitleAndImageWrapper from "../TitleAndImageLayout";
import { ImageType } from "../../constants/Image";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

type DefaultProps = {
  title: string;
  subtitle?: string;
};

const Default: FC<DefaultProps> = ({ title, subtitle }) => {
  const navigate = useNavigate();
  return (
    <TitleAndImageWrapper
      title={title}
      subtitle={subtitle}
      image={ImageType.STAIRS}
    >
      <Box alignItems={"center"} mb={4}>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          sx={{ mt: 2, marginX: "auto", mr: 2 }}
          onClick={() => navigate("/")}
        >
          Go Home
        </Button>
      </Box>
    </TitleAndImageWrapper>
  );
};

export default Default;
