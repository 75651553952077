import { AppState, LocalStorageCache, useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getEnv from "../../env/environment";
import { getTokenAsync } from "../../lib/api";
import Loader from "../../components/Loader";
import { routes } from "../../constants/Constants";
import { SetCookieRaw } from "../../lib/helpers";
import { Buffer } from "buffer";
import { AuthAppState, Result } from "../../contracts/common";
import { ErrorV1, TermsUpdatedResponseV1 } from "../../contracts/api";
import { useHandleError } from "../../hooks/useHandleError";
import { usePeekAuth0SessionStorage } from "../../hooks/usePeekAuth0SessionStorage";
import { useLoginEvents } from "../../hooks/useLoginEvents";
import { Stage } from "../../utils/enums/stage";

export const OnRedirectCallback = (appState?: AppState | undefined) => {
  if (appState) {
    const queryString = `?appState=${appState.appState}`;
    window.location.search = queryString;
  }
};

export default function HandleCallback() {
  const {
    handleRedirectCallback,
    getAccessTokenSilently,
    isLoading,
    isAuthenticated,
    user,
  } = useAuth0();

  usePeekAuth0SessionStorage("HandleCallback");

  const navigate = useNavigate();
  const { handleError } = useHandleError();
  const { successEvent, errorEvent } = useLoginEvents();

  useEffect(() => {
    if (isLoading) return;
    let defaultUrl = getEnv().URL_SMB;
    const queryParameters = new URLSearchParams(window.location.search);
    const stateb64 = queryParameters.get("appState");
    if (stateb64) {
      const stateJson = Buffer.from(stateb64, "base64").toString("utf8");
      const state = JSON.parse(stateJson) as AuthAppState;
      if (state.returnToMsa) {
        defaultUrl = getEnv().URL_MSA;
      }
    }

    const initAuth0 = async () => {
      successEvent(Stage.START_HANDLE_CALLBACK);
      try {
        if (!isAuthenticated) {
          await handleRedirectCallback();
          return;
        }

        let access_token = "",
          refresh_token = "";

        try {
          access_token = await getAccessTokenSilently();
          successEvent(Stage.READ_ACCESS_TOKEN);
        } catch (error) {
          console.error(error);
          errorEvent(Stage.READ_ACCESS_TOKEN, { access_token });
          handleError("FAILED_TO_GET_ACCESS_TOKEN");
          return;
        }

        const localStorageCache = new LocalStorageCache();
        try {
          const key = localStorageCache
            .allKeys()
            .find(
              (key) =>
                key.includes("auth0spa") && key.includes("offline_access"),
            );
          if (key !== undefined) {
            const refresh_token_value = await localStorageCache.get(key);
            refresh_token = (refresh_token_value as any).body.refresh_token;
            localStorage.setItem("access_token", access_token);
            localStorage.setItem("refresh_token", refresh_token);
          }
          successEvent(Stage.READ_REFRESH_TOKEN);
        } catch (error) {
          console.error(error);
          errorEvent(Stage.READ_REFRESH_TOKEN, {
            refresh_token,
            errorCode: "0x002",
          });
          handleError("FAILED_LOCALSTORAGE");
          return;
        }

        let getTokenResult: Result<TermsUpdatedResponseV1, ErrorV1>;
        try {
          getTokenResult = await getTokenAsync(access_token);
          successEvent(Stage.FETCH_EXTERNAL_TOKEN);
        } catch (error) {
          console.error(error);
          errorEvent(Stage.FETCH_EXTERNAL_TOKEN, { errorCode: "0x003" });
          handleError("FAILED_TO_GET_TOKEN_0x003");
          return;
        }

        if (getTokenResult.Success) {
          if (getTokenResult.Value?.terms_version !== undefined) {
            navigate(routes.acceptTerms);
            return;
          }

          const errorResponse = getTokenResult.Value as any as ErrorV1;
          if (errorResponse?.message === "USER_NOT_APPROVED") {
            console.debug(`user not approved ${user?.sub}`);
            localStorage.clear();
            navigate(routes.holdOnASecond, { state: errorResponse });
            return;
          }

          let redirectUrl = defaultUrl;
          if (
            defaultUrl === getEnv().URL_SMB &&
            !!getTokenResult.LocationHeader &&
            getTokenResult.LocationHeader.length > 0
          ) {
            redirectUrl = getTokenResult.LocationHeader!;
          }

          const tokenCookieValue = {
            access_token: access_token,
            refresh_token: refresh_token,
          };

          SetCookieRaw("auth_tokens", JSON.stringify(tokenCookieValue), "/");

          successEvent(Stage.SAVE_EXTERNAL_TOKEN);

          window.location.assign(
            `${redirectUrl}?access_token=${access_token}&refresh_token=${refresh_token}`,
          );
          return;
        }

        console.error(`Failed to get token for ${user?.sub}`);
        console.error(JSON.stringify(getTokenResult.Error, null, 2));
        localStorage.clear();
        errorEvent(Stage.SAVE_EXTERNAL_TOKEN, {
          getTokenResultSuccess: getTokenResult.Success,
          errorCode: "0x004",
        });
        handleError("FAILED_TO_GET_TOKEN_0x004");
        return;
      } catch (error) {
        console.error(error);
        try {
          console.error(JSON.stringify(error, null, 2));
        } catch (error) {
          console.error("failed to stringify error object");
        }
        localStorage.clear();
        errorEvent(Stage.GENERAL, {
          errorText: JSON.stringify(error, null, 2),
          errorCode: "0x000",
        });
        handleError("FAILED_TO_AUTHENTICATE");
        return;
      }
    };

    initAuth0();
  }, [
    getAccessTokenSilently,
    handleRedirectCallback,
    navigate,
    isAuthenticated,
    isLoading,
    user,
    handleError,
    errorEvent,
    successEvent,
  ]);

  return <Loader />;
}
