import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { logoutAsync } from "../../lib/api";
import { SetCookieRaw } from "../../lib/helpers";
import Loader from "../../components/Loader";
import { usePeekAuth0SessionStorage } from "../../hooks/usePeekAuth0SessionStorage";
import { useLoginEvents } from "../../hooks/useLoginEvents";
import { Stage } from "../../utils/enums/stage";

export default function HandleLogout() {
  const { logout, isLoading } = useAuth0();

  usePeekAuth0SessionStorage("HandleLogout");
  const { successEvent, errorEvent } = useLoginEvents();

  useEffect(() => {
    if (isLoading) return;

    const callLogout = async () => {
      try {
        const refresh_token =
          localStorage.getItem("refresh_token") ?? undefined;
        localStorage.clear();
        SetCookieRaw("auth_tokens", "", "/");
        await logoutAsync({ refresh_token: refresh_token });
        successEvent(Stage.LOGOUT);
      } catch (error) {
        errorEvent(Stage.LOGOUT, {});
        console.error(error);
      }

      logout();
    };

    callLogout();
  }, [logout, isLoading, errorEvent, successEvent]);

  return <Loader />;
}
