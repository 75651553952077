import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ErrorPage from "./Error";
import HoldOnASecondPage from "./HoldOnASecond";
// import LandlordMigration from '../pages/LandlordMigration';
import { routes } from "../constants/Constants";
import HandleCallback from "./auth0/HandleCallback";
import HandleLogout from "./auth0/HandleLogout";
import RedirectToLogin from "./auth0/RedirectToLogin";
import SetPasswordPage from "./SetPasswordPage";
import PageContainer from "../components/PageContainer";
import { AppContext, HeaderNav } from "../lib/context";
import { useState } from "react";
// import VerifyEmailPage from "./VerifyEmailPage";
// import RenterMigration from "./RenterMigration";
// import BeforeYouSignInPage from "./BeforeYouSignIn";
import AcceptTermsPage from "./AcceptTermsPage";
// import BrokerMigration from "./BrokerMigration";
import YouAreAllSet from "./YouAreAllSet";
import HandleOktaSignIn from "./okta/HandleOktaSignIn";
import HandleOktaCallback from "./okta/HandleOktaCallback";
import HandleOktaToken from "./okta/HandleOktaToken";
import HandleOktaMsaSignIn from "./okta/HandleOktaMsaSignIn";
import HandleOktaMsaCallback from "./okta/HandleOktaMsaCallback";
import HandleOktaMsaToken from "./okta/HandleOktaMsaToken";
import HandleSSOCallback from "./sso/HandleSSOCallback";
// import CreateAnAccount from "./CreateAnAccountPage";
import SignUpThankyouPage from "./SignUpThankyouPage";

export default function App() {
  const [state, setState] = useState<HeaderNav>({});

  return (
    <AppContext.Provider value={{ state, setState }}>
      <PageContainer>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to={routes.signIn} replace />} />
            <Route path={routes.signIn} element={<RedirectToLogin />} />
            <Route
              path={routes.renterSignInMsa}
              element={<RedirectToLogin returnToMsa={true} />}
            />
            <Route path={routes.setPassword} element={<SetPasswordPage />} />
            {/* <Route path={routes.verifyEmailMigration} element={<VerifyEmailPage />} /> */}

            {/* <Route path={routes.landlordMigration} element={<LandlordMigration />} />
            <Route path={routes.landlordMigrationSubmitEmail} element={<LandlordMigration />} />
            <Route path={routes.landlordMigrationVerifyPassword} element={<LandlordMigration />} />
            <Route path={routes.landlordMigrationSelectRole} element={<LandlordMigration />} />
            <Route path={routes.landlordMigrationUpdateAccount} element={<LandlordMigration />} />
            <Route path={routes.landlordMigrationFinished} element={<LandlordMigration />} />
            <Route path={routes.landlordMigrationNotRecognised} element={<LandlordMigration />} />
            <Route path={routes.landlordMigrationCheckEmail} element={<LandlordMigration />} /> */}
            {/* 
            <Route path={routes.renterMigration} element={<RenterMigration />} />
            <Route path={routes.renterMigrationSubmitEmail} element={<RenterMigration />} />
            <Route path={routes.renterMigrationUpdateAccount} element={<RenterMigration />} />
            <Route path={routes.renterMigrationCheckEmail} element={<RenterMigration />} />
            <Route path={routes.renterMigrationNotRecognised} element={<RenterMigration />} />
            <Route path={routes.renterMigrationFinished} element={<RenterMigration />} /> */}

            {/* <Route path={routes.brokerMigration} element={<BrokerMigration />} />
            <Route path={routes.brokerMigrationSubmitEmail} element={<BrokerMigration />} />
            <Route path={routes.brokerMigrationUpdateAccount} element={<BrokerMigration />} />
            <Route path={routes.brokerMigrationCheckEmail} element={<BrokerMigration />} />
            <Route path={routes.brokerMigrationNotRecognised} element={<BrokerMigration />} />
            <Route path={routes.brokerMigrationFinished} element={<BrokerMigration />} /> */}

            <Route path={routes.callback} element={<HandleCallback />} />
            <Route path={routes.signOut} element={<HandleLogout />} />

            <Route path={routes.acceptTerms} element={<AcceptTermsPage />} />
            <Route path={routes.allSet} element={<YouAreAllSet />} />
            {/* <Route path={routes.signUpRenters} element={<CreateAnAccount />} /> */}
            <Route path={routes.thankyou} element={<SignUpThankyouPage />} />

            <Route path={routes.oktaSignIn} element={<HandleOktaSignIn />} />
            <Route
              path={routes.oktaCallback}
              element={<HandleOktaCallback />}
            />
            <Route path={routes.oktaToken} element={<HandleOktaToken />} />

            <Route
              path={routes.oktaMsaSignIn}
              element={<HandleOktaMsaSignIn />}
            />
            <Route
              path={routes.oktaMsaCallback}
              element={<HandleOktaMsaCallback />}
            />
            <Route
              path={routes.oktaMsaToken}
              element={<HandleOktaMsaToken />}
            />

            <Route
              path={routes.auth0SSOCallback}
              element={<HandleSSOCallback />}
            />

            <Route
              path={routes.holdOnASecond}
              element={<HoldOnASecondPage />}
            />

            <Route path="/:id" element={<ErrorPage />} />
          </Routes>
        </Router>
      </PageContainer>
    </AppContext.Provider>
  );
}
