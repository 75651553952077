import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import getEnv from "../env/environment";

export const routes = {
  home: `${getEnv().MARKETING_WEBSITE}/`,
  faqsLandlord: `${getEnv().MARKETING_WEBSITE}/faq-for-operators`,
  setPassword: "/set-password",
  verifyEmailMigration: "/verify-email-migration",
  forgotPassword: "/forgot-password",
  default: "/",
  signIn: "/sign-in",
  renterSignInMsa: "/renters/sign-in",
  genericSignUp: `${getEnv().MARKETING_WEBSITE}/get-started`,
  acceptTerms: "/accept-terms",
  signUpLandlords: "https://propertymanager.theguarantors.com/units-owned",
  signUpRenters: "/renters/sign-up",
  signUpBrokers: `${getEnv().MARKETING_WEBSITE}/broker/sign-up`,
  callback: "/callback",
  signOut: "/logout",
  allSet: "/finished",
  thankyou: "/thankyou",
  terms: "/terms",
  error: "/error",
  holdOnASecond: "/hold-on-a-second",

  renterMigration: "/renter-migration",
  renterMigrationSubmitEmail: "/renter-migration/submit-email",
  renterMigrationUpdateAccount: "/renter-migration/update-account",
  renterMigrationCheckEmail: "/renter-migration/check-your-email",
  renterMigrationNotRecognised: "/renter-migration/not-recognised",
  renterMigrationFinished: "/renter-migration/Finished",

  brokerMigration: "/broker-migration",
  brokerMigrationSubmitEmail: "/broker-migration/submit-email",
  brokerMigrationUpdateAccount: "/broker-migration/update-account",
  brokerMigrationCheckEmail: "/broker-migration/check-your-email",
  brokerMigrationNotRecognised: "/broker-migration/not-recognised",
  brokerMigrationFinished: "/broker-migration/Finished",

  landlordMigration: "/landlord-migration",
  landlordMigrationSubmitEmail: "/landlord-migration/submit-email",
  landlordMigrationVerifyPassword: "/landlord-migration/verify-password",
  landlordMigrationSelectRole: "/landlord-migration/select-role",
  landlordMigrationUpdateAccount: "/landlord-migration/update-account",
  landlordMigrationFinished: "/landlord-migration/finished",
  landlordMigrationNotRecognised: "/landlord-migration/not-recognised",
  landlordMigrationCheckEmail: "/landlord-migration/check-your-email",

  oktaSignIn: "/okta",
  oktaCallback: "/okta/callback",
  oktaToken: "/okta/token",

  oktaMsaSignIn: "/okta/msa",
  oktaMsaCallback: "/okta/msa/callback",
  oktaMsaToken: "/okta/msa/token",

  auth0SSOCallback: "/sso/:id/callback",
};

export interface FooterLinkProp {
  title: string;
  url: string;
}

export const footerLinks: { [name: string]: FooterLinkProp } = {
  home: {
    title: "© 2023 TheGuarantors Inc.",
    url: routes.home,
  },
  privacy: {
    title: "Privacy Policy",
    url: `${getEnv().MARKETING_WEBSITE}/privacy`,
  },
  terms: {
    title: "Terms of Service",
    url: `${getEnv().MARKETING_WEBSITE}/terms`,
  },
  legal: {
    title: "Legal/Regulatory Notice",
    url: `${getEnv().MARKETING_WEBSITE}/legal-regulatory`,
  },
};

export const externalTitles = {
  privacy: "Privacy Policy",
  terms: "Terms of Service",
  legalRegulatory: "Legal/Regulatory Notice",
  helpEmail: "Help Email",
  faq: "FAQ",
  noticeCollection: "Notice of Collection",
};

export const externalLinks = {
  privacy: `${getEnv().MARKETING_WEBSITE}/privacy?hsLang=en`,
  terms: `${getEnv().MARKETING_WEBSITE}/terms?hsLang=en`,
  legalRegulatory: `${getEnv().MARKETING_WEBSITE}/legal-regulatory?hsLang=en`,
  helpEmail: "landlord@theguarantors.com",
  faq: `${getEnv().MARKETING_WEBSITE}/enroll-property#faq`,
  noticeCollection: "https://www.theguarantors.com/notice-of-collection",
};

export const footerMenu = [
  {
    title: externalTitles.privacy,
    to: externalLinks.privacy,
  },

  {
    title: externalTitles.terms,
    to: externalLinks.terms,
  },

  {
    title: externalTitles.legalRegulatory,
    to: externalLinks.legalRegulatory,
  },
  {
    title: externalTitles.noticeCollection,
    to: externalLinks.noticeCollection,
  },
];

export const SocialLinks = [
  {
    icon: <LinkedInIcon />,
    link: "https://www.linkedin.com/company/the-guarantors",
    title: "LinkedIn",
  },
  {
    icon: <TwitterIcon />,
    link: "https://twitter.com/The_Guarantors",
    title: "Twitter",
  },
  {
    icon: <FacebookIcon />,
    link: "https://www.facebook.com/TheGuarantors",
    title: "Facebook",
  },
  {
    icon: <InstagramIcon />,
    link: "https://www.instagram.com/theguarantors",
    title: "Instagram",
  },
];
