import { useEffect } from "react";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router";
import { routes } from "../../constants/Constants";
import { InitOktaMsaClient } from "./OktaClient";

export default function HandleOktaMsaCallback() {
  const navigate = useNavigate();
  const oktaAuth = InitOktaMsaClient();

  useEffect(() => {
    const initOktaAuth = async () => {
      await oktaAuth.handleRedirect();
      const access_token = oktaAuth.getAccessToken();
      if (access_token === undefined) {
        navigate("/error", { state: "Contact the Auth team for assistance." });
        return;
      }

      navigate(routes.oktaMsaToken);
    };

    initOktaAuth();
  }, [oktaAuth, navigate]);

  return <Loader />;
}
