import {
  CreateUserRequestV1,
  CreateUserResponseV1,
  CreateMigrationRequestV1,
  GetMigrationResponseV1,
  ValidatePasswordRequestV1,
  ValidatePasswordResponseV1,
  CompleteMigrationRequestV1,
  ErrorV1,
  SetPasswordResponseV1,
  SetPasswordRequestV1,
  UpdateUserRequestV1,
  TermsUpdatedResponseV1,
  LogoutRequestV1,
  TokenResponseV1,
} from "../contracts/api";
import { Result } from "../contracts/common";
import getEnv from "../env/environment";
import axios, { AxiosResponse } from "axios";
import { Buffer } from "buffer";

export const getTokenAsync = (
  access_token: string,
): Promise<Result<TermsUpdatedResponseV1, ErrorV1>> =>
  sendRequestAsync(
    `${getEnv().LOGIN_API_DOMAIN}/v1/token`,
    "POST",
    undefined,
    access_token,
  );

export const logoutAsync = (
  data: LogoutRequestV1,
): Promise<Result<void, ErrorV1>> =>
  sendRequestAsync(`${getEnv().LOGIN_API_DOMAIN}/v1/logout`, "POST", data);

export const createRenterProfileAsync = (
  data: CreateUserRequestV1,
): Promise<Result<CreateUserResponseV1, ErrorV1>> =>
  sendRequestAsync(
    `${getEnv().USER_API_DOMAIN}/v1/profiles/renter`,
    "POST",
    data,
  );

export const createMigrationAsync = (
  data: CreateMigrationRequestV1,
): Promise<Result<GetMigrationResponseV1, ErrorV1>> =>
  sendRequestAsync(`${getEnv().USER_API_DOMAIN}/v1/migration`, "POST", data);

export const getMigrationAsync = (
  migration_id: string,
): Promise<Result<GetMigrationResponseV1, ErrorV1>> =>
  getAsync(`${getEnv().USER_API_DOMAIN}/v1/migration/${migration_id}`);

export const ssoCodeExchange = (
  idp: string,
  code: string,
): Promise<Result<TokenResponseV1, ErrorV1>> =>
  getAsync(`${getEnv().LOGIN_API_DOMAIN}/v1/sso/${idp}/callback?code=${code}`);

export const validatePasswordAsync = (
  migration_id: string,
  data: ValidatePasswordRequestV1,
): Promise<Result<ValidatePasswordResponseV1, ErrorV1>> =>
  sendRequestAsync(
    `${getEnv().USER_API_DOMAIN}/v1/migration/${migration_id}/verify-existing-password`,
    "POST",
    data,
  );

export const completeMigrationAsync = (
  migration_id: string,
  data: CompleteMigrationRequestV1,
): Promise<Result<void, ErrorV1>> =>
  sendRequestAsync(
    `${getEnv().USER_API_DOMAIN}/v1/migration/${migration_id}/complete`,
    "POST",
    data,
  );

export const setPasswordAsync = (
  signedLink: string,
  data: SetPasswordRequestV1,
): Promise<Result<SetPasswordResponseV1, ErrorV1>> =>
  sendRequestAsync(
    `${getEnv().USER_API_DOMAIN}/v1/users/set-password`,
    "POST",
    data,
    undefined,
    signedLink,
  );

export const verifySignedLinkAsync = (
  signedLink: string,
): Promise<Result<void, ErrorV1>> =>
  sendRequestAsync(
    `${getEnv().USER_API_DOMAIN}/v1/links/verify`,
    "POST",
    undefined,
    undefined,
    signedLink,
  );

export async function setTermsVersionAsync(
  termsVersion: string,
  accessToken: string,
): Promise<Result<SetPasswordResponseV1, ErrorV1>> {
  const data: UpdateUserRequestV1 = {
    terms_version_accepted: termsVersion,
  };

  return await sendRequestAsync(
    `${getEnv().USER_API_DOMAIN}/v1/users`,
    "PATCH",
    data,
    accessToken,
  );
}

export const sendVerifyEmailMigrationAsync = (
  migration_id: string,
): Promise<Result<boolean, ErrorV1>> =>
  sendRequestAsync(
    `${getEnv().USER_API_DOMAIN}/v1/migration/${migration_id}/send-verify-email`,
    "POST",
  );

export const verifyEmailMigrationAsync = (
  migration_id: string,
  signedLink: string,
): Promise<Result<GetMigrationResponseV1, ErrorV1>> =>
  sendRequestAsync(
    `${getEnv().USER_API_DOMAIN}/v1/migration/${migration_id}/verify-email`,
    "POST",
    undefined,
    undefined,
    signedLink,
  );

export async function getAsync<TResponse>(
  url: string,
  accessToken?: string,
): Promise<Result<TResponse, ErrorV1>> {
  try {
    const headers: { [key: string]: string } = {
      "Content-Type": "application/json",
    };

    if (accessToken !== undefined)
      headers["Authorization"] = `Bearer ${accessToken}`;

    const response = await axios<TResponse>({
      url: url,
      method: "GET",
      headers: headers,
      withCredentials: true,
    });

    return await handleResponse(response);
  } catch (error) {
    console.error(error);
    return new Result(
      undefined as TResponse,
      new ErrorV1(-1, String(parseError)),
    );
  }
}

export async function sendRequestAsync<TRequest, TResponse>(
  url: string,
  method: string,
  body?: TRequest,
  accessToken?: string,
  signedLink?: string,
): Promise<Result<TResponse, ErrorV1>> {
  try {
    const headers: { [key: string]: string } = {
      "Content-Type": "application/json",
    };

    if (accessToken !== undefined)
      headers["Authorization"] = `Bearer ${accessToken}`;
    else if (signedLink !== undefined)
      headers["Authorization"] = Buffer.from(signedLink, "utf8").toString(
        "base64",
      );

    const response = await axios<TResponse>({
      url: url,
      data: body,
      method: method,
      headers: headers,
      withCredentials: true,
      validateStatus: (status: number) => {
        return [200, 201, 202, 204, 400, 401, 403, 404, 409].includes(status);
      },
    });

    return await handleResponse(response);
  } catch (error) {
    console.error(error);
    return new Result(
      undefined as TResponse,
      new ErrorV1(-1, String(parseError)),
    );
  }
}

async function handleResponse<TResponse>(
  response: AxiosResponse<TResponse, any>,
): Promise<Result<TResponse, ErrorV1>> {
  const success = response.status.toString().search(/(?:20[0-4]|403)/g);

  if (success !== -1) {
    const contentType = response.headers["content-type"];
    const locationHeader =
      response.headers["Location"] ?? response.headers["location"];
    if (
      contentType &&
      contentType.indexOf("application/json") !== -1 &&
      response.status.toString() !== "204"
    ) {
      try {
        const data = (await response.data) as TResponse;
        return new Result(
          data,
          undefined as any as ErrorV1,
          locationHeader as string,
        );
      } catch (parseError) {
        console.error(parseError);
        return new Result(
          undefined as TResponse,
          new ErrorV1(response.status, String(parseError)),
          locationHeader as string,
        );
      }
    }

    if (response.status.toString() === "204") {
      return new Result(
        undefined as TResponse,
        undefined as any as ErrorV1,
        locationHeader as string,
      );
    }
  }

  return new Result(undefined as TResponse, await parseError(response));
}

const parseError = async (
  response: AxiosResponse<any, any>,
): Promise<ErrorV1> => {
  try {
    const error = (await response.data) as ErrorV1;
    error.StatusCode = response.status;
    return error;
  } catch (err) {
    console.error(err);
    return new ErrorV1(response.status, String(err));
  }
};
