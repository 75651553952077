export interface EnvironmentKeys {
  LOGIN_API_DOMAIN: string;
  USER_API_DOMAIN: string;
  URL_SMB: string;
  URL_MSA: string;
  URL_MSA_ADMIN: string;
  URL_ADMIN_PORTAL: string;

  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_REDIRECT_URI: string;
  AUTH0_SCOPES: string;

  OKTA_CLIENT_ID: string;
  OKTA_MSA_CLIENT_ID: string;
  OKTA_ISSUER_URL: string;
  OKTA_CALLBACK_URL: string;
  OKTA_MSA_CALLBACK_URL: string;

  CURRENT_TERMS_VERSION: string;

  CONTAINER_MODE: boolean;
  DD_APP_ID: string;
  DD_CLIENT_ID: string;
  DD_ENV: string;

  SEGMENT_API_KEY: string;
  MARKETING_WEBSITE: string;
}

export default function getEnv(): EnvironmentKeys {
  const defaultEnv = {
    LOGIN_API_DOMAIN: "http://localhost:5001/login-api.com",
    USER_API_DOMAIN: "http://localhost:5001/user-api.com",
    URL_SMB: "http://localhost:5001/smb.com",
    URL_MSA: "http://localhost:5001/msa.com",
    URL_MSA_ADMIN: "http://localhost:5001/msa.com",
    URL_ADMIN_PORTAL: "http://localhost:5001/admin.com",
    AUTH0_DOMAIN: "tg-auth-development.us.auth0.com",
    AUTH0_CLIENT_ID: "4tgeLMjhPNeY8wGlDYgBqCJTmlYYPInM",
    AUTH0_REDIRECT_URI: "http://localhost:3000/callback",
    AUTH0_SCOPES: "openid offline_access",
    OKTA_CLIENT_ID: "",
    OKTA_MSA_CLIENT_ID: "",
    OKTA_ISSUER_URL: "",
    OKTA_CALLBACK_URL: "",
    OKTA_MSA_CALLBACK_URL: "",
    CURRENT_TERMS_VERSION: "v1.0.0",
    DD_APP_ID: "d23e1463-0f05-4cee-ace9-541c24494720",
    DD_CLIENT_ID: "pub5c2f56847a45e7ef2939c1b76db6ed2d",
    DD_ENV: "development",
    CONTAINER_MODE: false,
    SEGMENT_API_KEY: "",
    MARKETING_WEBSITE: "https://test.theguarantors.com",
  };
  if (!!window) {
    (window as any)["env"] = {
      ...defaultEnv,
      ...((window as any)["env"] || {}),
    };
  }
  return (window as any)["env"];
}
