import {
  Box,
  Dialog,
  Button,
  useMediaQuery,
  useTheme,
  SwipeableDrawer,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Terms from "../../constants/Terms";

export interface TermsMetaData {
  modal: boolean;
  setModal: (isTermsRead: boolean) => void;
}

export const TermsModal = ({ modal, setModal }: TermsMetaData) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const DrawerContent = () => (
    <>
      <Stack direction="row" mb={2} justifyContent="space-between">
        <Typography variant="h5" id={"scroll-dialog-title"} component="h1">
          Terms & Conditions
        </Typography>
        <Box>
          <IconButton
            onClick={() => {
              setModal(!modal);
            }}
            sx={{ transform: "translate(12px, -8px)" }}
          >
            <CloseIcon sx={{ color: "black" }} />
          </IconButton>
        </Box>
      </Stack>
      <Box id="modal">
        <Box
          sx={{
            overflow: "scroll",
            height: "62vh",
            p: 2,
            bgcolor: "rgba(61, 177, 87, .15)",
            lineHeight: "24px",
            a: {
              color: "#777777",
            },
          }}
        >
          <Terms />
        </Box>
        <Button
          sx={{ float: "right", mt: { xs: 1, sm: 5 } }}
          variant="contained"
          id={"terms-agree-button"}
          onClick={() => {
            setModal(!modal);
          }}
        >
          Close
        </Button>
      </Box>
    </>
  );

  if (!isMobile) {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => setModal(!modal)}
        open={modal}
        PaperProps={{
          style: { borderRadius: 20 },
        }}
      >
        <Box m={4}>
          <DrawerContent />
        </Box>
      </Dialog>
    );
  } else {
    return (
      <SwipeableDrawer
        PaperProps={{ square: false }}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "30px 30px 0 0",
          },
        }}
        anchor="bottom"
        onOpen={() => setModal(!modal)}
        onClose={() => setModal(!modal)}
        open={modal}
      >
        <Box m={4} mx={2} mb={1}>
          <DrawerContent />
        </Box>
      </SwipeableDrawer>
    );
  }
};
