import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ImageType } from "../../constants/Image";
import TitleAndImageWrapper from "../../components/TitleAndImageLayout";
import { type FC } from "react";

type LogoutInstructionsProps = {
  errorCode?: string;
};

const LogoutInstructions: FC<LogoutInstructionsProps> = ({ errorCode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const loginErrorMobileChildren = (
    <Box>
      <Typography style={{ textAlign: "center" }}>
        We’re sorry for the inconvenience and are working quickly to resolve the
        issue.
      </Typography>
      <Typography style={{ textAlign: "center" }}>
        While we sort this out, please follow these steps to troubleshoot:
      </Typography>
      <Typography style={{ textAlign: "center", marginTop: 32 }} variant="h6">
        Log out and log in again:
      </Typography>
      <Typography style={{ textAlign: "center", marginTop: 16 }} variant="h6">
        <a href="/logout" style={{ color: "inherit" }}>
          Click here to log out
        </a>
      </Typography>
      <Typography style={{ textAlign: "center", marginTop: 32 }}>
        Thank you for your patience and understanding. If you continue to
        experience issues, please contact our support team for further
        assistance.
      </Typography>
    </Box>
  );

  const loginErrorDesktopChildren = (
    <Box>
      <Typography style={{ textAlign: "center" }}>
        We’re sorry for the inconvenience and are working quickly to resolve the
        issue.
      </Typography>
      <Typography style={{ textAlign: "center" }}>
        While we sort this out, please follow these steps to troubleshoot:
      </Typography>
      <Stack direction={"row"} justifyContent="center">
        <Typography variant="h6">
          <ol>
            <li>
              Perform a hard refresh:
              <ul style={{ listStyleType: "none", marginTop: 16 }}>
                <li>
                  <Typography>
                    <b>Windows:</b> Control + F5
                  </Typography>
                </li>
                <li>
                  <Typography>
                    <b>Mac:</b> Command + Shift + R
                  </Typography>
                </li>
              </ul>
            </li>
            <li style={{ marginTop: 32 }}>
              Log out and log in again:
              <ul style={{ listStyleType: "none", marginTop: 16 }}>
                <li>
                  <Typography>
                    <a href="/logout" style={{ color: "inherit" }}>
                      Click here to log out
                    </a>
                  </Typography>
                </li>
              </ul>
            </li>
          </ol>
        </Typography>
      </Stack>
      <Typography style={{ textAlign: "center" }}>
        Thank you for your patience and understanding. If you continue to
        experience issues, please contact our support team for further
        assistance.
      </Typography>
    </Box>
  );

  return (
    <TitleAndImageWrapper
      title={"Uh oh, a login error occurred"}
      subtitle={errorCode}
      image={ImageType.STAIRS}
    >
      {isMobile ? loginErrorMobileChildren : loginErrorDesktopChildren}
    </TitleAndImageWrapper>
  );
};

export default LogoutInstructions;
