import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import getEnv from "../../env/environment";
import { ssoCodeExchange } from "../../lib/api";
import Loader from "../../components/Loader";
import { ErrorV1 } from "../../contracts/api";

export default function HandleSSOCallback() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [defaultUrl, setDefaultUrl] = useState(getEnv().URL_SMB);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  if (!id || !code) {
    navigate("/error", { state: "AUTH_CODE_NOT_FOUND" });
  }

  useEffect(() => {
    const getToken = async () => {
      try {
        const getTokenResult = await ssoCodeExchange(id!, code!);

        if (getTokenResult.Success) {
          localStorage.setItem(
            "access_token",
            getTokenResult.Value!.access_token,
          );
          localStorage.setItem(
            "refresh_token",
            getTokenResult.Value!.refresh_token,
          );

          // if (getTokenResult.Value?.terms_version !== undefined) {
          //     navigate(routes.acceptTerms)
          //     return
          // }

          let redirectUrl = defaultUrl;
          if (
            defaultUrl === getEnv().URL_SMB &&
            !!getTokenResult.LocationHeader &&
            getTokenResult.LocationHeader.length > 0
          ) {
            redirectUrl = getTokenResult.LocationHeader!;
          }

          window.location.assign(
            `${redirectUrl}?access_token=${getTokenResult.Value!.access_token}&refresh_token=${getTokenResult.Value!.refresh_token}`,
          );
          return;
        }

        localStorage.clear();
        navigate("/error", {
          state: { message: "FAILED_TO_EXCHANGE_CODE" } as ErrorV1,
        });
      } catch (error) {
        console.error(error);
        localStorage.clear();
        navigate("/error", {
          state: { message: "FAILED_TO_EXCHANGE_CODE" } as ErrorV1,
        });
      }
    };

    getToken();
  }, [navigate, defaultUrl, setDefaultUrl, code, id]);

  return <Loader />;
}
