export interface CreateUserRequestV1 {
  email: string;
  password: string;
  terms_version_accepted: string;
}

export interface CreateUserResponseV1 {
  user_id: string;
}

export interface CreateMigrationRequestV1 {
  email: string;
}

export interface GetMigrationResponseV1 {
  migration_id: string;
  status: string;
  has_password?: boolean;
  company_name?: string;
  first_name?: string;
  last_name?: string;
  is_migrated?: boolean;
}

export interface ValidatePasswordRequestV1 {
  password: string;
}

export interface ValidatePasswordResponseV1 {
  is_valid: boolean;
}

export interface CompleteMigrationRequestV1 {
  email: string;
  password: string;
  terms_version: string;
  company_role?: string;
  first_name?: string;
  last_name?: string;
}

export interface SetPasswordRequestV1 {
  password: string;
  terms_version?: string;
}

export interface SetPasswordResponseV1 {
  redirect_uri: string;
}

export interface UpdateUserRequestV1 {
  terms_version_accepted?: string;
}

export interface TermsUpdatedResponseV1 {
  terms_version?: string;
}

export class ErrorV1 {
  public StatusCode: number;
  // Lower case
  public message: string;
  public errorCode?: string;
  public validation_errors?: string[];

  constructor(statusCode: number, message: string, errorCode?: string) {
    this.StatusCode = statusCode;
    this.message = message;
    this.errorCode = errorCode;
  }
}

export interface LogoutRequestV1 {
  refresh_token?: string;
}

export interface TokenResponseV1 {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
}
