import { datadogLogs } from "@datadog/browser-logs";
import { Stage } from "../utils/enums/stage";

type Status = "info" | "error";

const logDatadogEvent = (
  status: Status,
  stage: Stage,
  customMetadata: object,
) => {
  datadogLogs.logger[status]("Login Event", {
    stage,
    customMetadata,
  });
};

export const useLoginEvents = () => {
  return {
    successEvent: (stage: Stage, metadata?: object) =>
      logDatadogEvent("info", stage, metadata ?? {}),
    errorEvent: (stage: Stage, metadata: object) =>
      logDatadogEvent("error", stage, metadata),
  };
};
