import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ImageType } from "../constants/Image";
import TitleAndImageWrapper from "../components/TitleAndImageLayout";

export default function HoldOnASecond() {
  const navigate = useNavigate();

  const title = `Hold on a second...`;
  const subtitle = `We're sorry, but your account still needs to be approved`;

  return (
    <TitleAndImageWrapper
      title={title}
      subtitle={subtitle}
      image={ImageType.STAIRS}
      children={
        <Box alignItems={"center"} mb={4}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            sx={{ mt: 2, marginX: "auto", mr: 2 }}
            onClick={() => navigate("/")}
          >
            Go Home
          </Button>
        </Box>
      }
    />
  );
}
