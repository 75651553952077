import { Typography, Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { GetImage, ImageType } from "../constants/Image";
import useProgressStore from "../store/progressStore";
import { useEffect } from "react";

const TitleAndImageWrapper = ({
  title,
  subtitle,
  image,
  maxWidth,
  children,
  showProgress,
}: {
  title: string;
  subtitle?: string;
  image: ImageType;
  maxWidth?: number;
  children?: JSX.Element | JSX.Element[];
  showProgress?: boolean;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { setProgress } = useProgressStore();

  useEffect(() => {
    if (showProgress) {
      setProgress(100);
    }
  }, [setProgress, showProgress]);

  return (
    <Box px={2}>
      <Stack
        direction="column"
        alignContent="center"
        alignItems="center"
        maxWidth={maxWidth ?? 650}
        marginX={"auto"}
      >
        <Typography
          mb={2}
          variant="h2"
          align="center"
          id={`HeaderLayout-title-${title.replace(/[\W_]+/g, "_")}`}
        >
          {title}
        </Typography>
        {!!subtitle ? (
          <Typography align="center" mb={2}>
            {subtitle}
          </Typography>
        ) : null}
        {children}
        <img
          src={GetImage(image)}
          alt={image}
          style={{ width: isMobile ? 350 : "unset" }}
        />
      </Stack>
    </Box>
  );
};
export default TitleAndImageWrapper;
