import { Link, Typography } from "@mui/material";

interface Props {
  text: string;
}

const Terms = () => {
  const ExternalLink = ({ text }: Props) => (
    <Link rel="noopener" href={text} target="_blank">
      {" "}
      {text}
    </Link>
  );

  return (
    <div>
      <Typography mb={0.5}>Last Modified: 10 April 2023</Typography>
      <Typography my={2} variant="h5">
        Acceptance of the Account Creation and Access Terms & Conditions
      </Typography>
      <Typography mb={2}>
        These Account Creation and Access Terms & Conditions (Terms &
        Conditions) are entered into by and between you and GuarantR, Inc.
        (“Company”, “we”, or “us”). The following Terms & Conditions, together
        with the Terms of Services found at
        <ExternalLink text="https://www.theguarantors.com/terms" />, our Privacy
        Policy, found at
        <ExternalLink text="https://www.theguarantors.com/privacy," /> the Bond
        General Terms & Conditions, and jurisdictional specifics, found at
        <ExternalLink text="https://www.theguarantors.com/general-terms-and-conditions" />{" "}
        (Bond Terms & Conditions) and any other documents executed by you or
        your affiliates or associated entity with the Company, (collectively,
        “Terms of Use”), govern your access to and use of
        https://www.theguarantors.com/, including any content, applications,
        platforms, dashboards, functionality, accounts, and services offered on
        or through
        <ExternalLink text="https://www.theguarantors.com/" />
        (the “Website”), whether as a guest (e.g. non-accountholder visiting and
        accessing the Website) or a registered user (e.g. accountholder visiting
        and accessing the Website).
      </Typography>
      <Typography mb={2}>
        Those individuals and entities serving as landlord under a covered lease
        and obligee under an executed bond are placed on notice that the Bond
        Terms & Conditions contain certain insurance fraud notices, which are
        incorporated by reference into these Terms & Conditions, and further,
        those individuals and entities shall comply with all applicable
        provisions of the Bond Terms & Conditions.
      </Typography>
      <Typography mb={2}>
        Please read these Terms & Conditions carefully before you start to use
        the Website or create and access an account. By clicking to accept or
        agree to the Terms & Conditions when this option is made available to
        you, you accept and agree to be bound and abide by these Terms &
        Conditions, our Terms of Services, found at
        <ExternalLink text="https://www.theguarantors.com/terms" />, and our
        Privacy Policy, found at
        <ExternalLink text="https://www.theguarantors.com/privacy" />,
        incorporated herein by reference. If you do not want to agree to these
        Terms & Conditions, Terms of Services, and/or the Privacy Policy, you
        must not access or use the Website nor create or access an account.
      </Typography>
      <Typography mb={2}>
        By entering your phone number, your email address, and submitting the
        account creation form, you consent to receive text messages and
        marketing emails from the Company. You can unsubscribe at any time by
        replying STOP or clicking “Unsubscribe” in emails.
      </Typography>
      <Typography mb={2}>
        By using this Website, you represent and warrant that you are of legal
        age to form a binding contract with the Company and meet all regulatory
        and statutory eligibility requirements. If you do not meet all of these
        requirements, you must not access or use the Website nor create or
        access an associated account.
      </Typography>
      <Typography
        mb={1}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Changes to the Terms & Conditions
      </Typography>
      <Typography mb={2}>
        We may revise and update these Terms & Conditions from time to time in
        our sole discretion. All changes are effective immediately when we post
        them, and apply to all access to and use of the Website and accounts
        thereafter. However, any changes to the dispute resolution provisions
        set out in Governing Law and Jurisdiction section will not apply to any
        disputes for which the parties have actual notice on or before the date
        the change is posted.
      </Typography>
      <Typography mb={2}>
        Your continued use of the Website and accessing or creating your account
        following the posting of revised Terms & Conditions means that you
        accept and agree to the changes. You are expected to check this page
        from time to time so you are aware of any changes, as they are binding
        on you.
      </Typography>
      <Typography
        mb={1}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Accessing the Website and Account Security
      </Typography>
      <Typography mb={2}>
        We reserve the right to withdraw or amend this Website and accounts, and
        any service or material we provide on the Website, in our sole
        discretion without notice. We will not be liable if for any reason all
        or any part of the Website is unavailable at any time or for any period.
        From time to time, we may restrict access to some parts of the Website
        and accounts, or the entire Website.
      </Typography>
      <Typography mb={1}> You are responsible for both:</Typography>
      <ul>
        <Typography mb={2}>
          <li>
            Making all arrangements necessary for you to have access to the
            Website.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Ensuring that all persons who access the Website through your
            internet connection are aware of these Terms & Conditions and comply
            with them.
          </li>
        </Typography>
      </ul>

      <Typography mb={2}>
        To access the Website, accounts, or some of the resources it offers, you
        may be asked to provide certain registration details or other
        information. It is a condition of your use of the Website and use of
        your account that all the information you provide on the Website is
        correct, current, and complete. You agree that all information you
        provide to register and set up an account with this Website or
        otherwise, including, but not limited to, through the use of any
        interactive features on the Website, is governed by our Privacy Policy
        <ExternalLink text="https://www.theguarantors.com/privacy" /> and you
        consent to all actions we take with respect to your information
        consistent with our Privacy Policy.
      </Typography>
      <Typography mb={2}>
        If you choose, or are provided with, a user name, password, or any other
        piece of information as part of our security procedures, you must treat
        such information as confidential, and you must not disclose it to any
        other person or entity.
        <b>
          You acknowledge that as a requirement for access to your account,
          Company requires the use of multifactor authentication measures of
          Company’s choosing.{" "}
        </b>
        You also acknowledge that your account is personal to you and agree not
        to provide any other person with access to this Website or portions of
        it using your user name, password, or other security information. You
        agree to notify us immediately of any unauthorized access to or use of
        your user name or password or any other breach of security. You also
        agree to ensure that you exit from your account at the end of each
        session. You should use particular caution when accessing your account
        from a public or shared computer so that others are not able to view or
        record your password or other personal information.
      </Typography>
      <Typography mb={2}>
        We have the right to disable any user name, password, or other
        identifier, whether chosen by you or provided by us, at any time in our
        sole discretion for any or no reason, including if, in our opinion, you
        have violated any provision of the Terms of Use.
      </Typography>
      <Typography
        mb={1}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        User Contributions and Integration of Your Data
      </Typography>
      <Typography mb={2}>
        Through your interaction with the Website and/or the creation and access
        of your account, certain information and data may be inputted by you
        concerning your personal information (e.g. name, email, phone number,
        address, SSN, etc.) and/or integrated to your account from an associated
        information system constituting, among other data, tenant details
        maintained, owned, and controlled by you, your affiliates, and
        affiliated entities (collectively, “Integrated Data”). Subject to
        applicable law, including the California Consumer Privacy Act of 2018,
        as amended by the California Privacy Rights Act of 2020, the Privacy
        Policy, and Terms of Use, you hereby grant Company and necessary
        third-party service providers and contractors a worldwide, non-exclusive
        license to the Integrated Data for all business purposes necessary and
        appropriate to provide the services in the Terms of Use. Further,
        subject to the same, Company may utilize Integrated Data for appropriate
        commercial purposes.
      </Typography>
      <Typography mb={1}>
        Consistent with the foregoing, and subject to applicable law and the
        Terms of Use, you understand, acknowledge, and agree that:
      </Typography>
      <ul>
        <Typography mb={2}>
          <li>
            Company and third-party service providers and contractors may use,
            modify, display, distribute, and create new material using the
            Integrated Data in accordance and connection with the provisions of
            the Terms of Use;
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            You may not copy, reproduce, distribute, or create derivative works
            from Integrated Data or any other content you receive through the
            Company’s service;
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Integrated Data can be accessed and viewed by individuals who
            support your relationship with Company and as otherwise required to
            provide the services of the Terms of Use;
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            You are responsible for any Integrated Data you submit or
            contribute, and you, not the Company, have full responsibility for
            such content, including its legality, reliability, accuracy, and
            appropriateness.
          </li>
        </Typography>
      </ul>

      <Typography mb={2}>
        Further, subject to the Terms of Use, you warrant and guarantee that (i)
        you have ownership and/or full power, right, and authority to authorize
        and permit Company and its third-party service providers and contractors
        to receive and use the Integrated Data; (ii) that the Integrated Data is
        accurate, current, and complete; (iii) you will only use the services
        for bona fide lawful purposes, not for purposes in violation of any
        applicable law or regulation, or in any other manner that, expressly or
        implicitly, violates the Terms of Use.
      </Typography>
      <Typography mb={2}>
        The Website may contain profiles, dashboards, platforms, and other
        interactive features (collectively, <b>“Interactive Services”</b>) that
        allow users to submit, display, or transmit Integrated Data to and from
        Company on or through the Website.
      </Typography>
      <Typography mb={2}>
        All information provided by you must comply with the Terms of Use,
        California Consumer Privacy Act of 2018, as amended by the California
        Privacy Rights Act of 2020, and any other applicable local, state, or
        federal laws and regulations.
      </Typography>
      <Typography mb={2}>
        We are not responsible or liable to any third party for the content or
        accuracy of any Integrated Data provided by you or any other user of the
        Website.
      </Typography>
      <Typography mb={2}>
        Integrated Data may include telephone and cell phone numbers
        (collectively, phone numbers) and Interactive Services may include
        communications from and to Company via the provided phone numbers.
        Standard messaging and data rates may apply.
      </Typography>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Intellectual Property Rights
      </Typography>
      <Typography mb={2}>
        The Website and its contents, features, and functionality (including but
        not limited to all information, software, text, displays, images, video,
        and audio, and the design, selection, and arrangement thereof) are owned
        by the Company, its licensors, or other providers of such material and
        are protected by United States and international copyright, trademark,
        patent, trade secret, and other intellectual property or proprietary
        rights laws.
      </Typography>
      <Typography mb={2}>
        These Terms & Conditions permit you to use the Website and associated
        account for exclusively your interaction with Company and the business
        purposes identified in the Terms of Use. You must not reproduce,
        distribute, modify, create derivative works of, publicly display,
        publicly perform, republish, download, store, or transmit any of the
        material on our Website, except as follows:
      </Typography>
      <ul>
        <Typography mb={2}>
          <li>
            In accordance with, and furtherance of, and pursuant to any
            provisions in the Terms of Use.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Your computer may temporarily store copies of such materials in RAM
            incidental to your accessing and viewing those materials.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            You may store files that are automatically cached by your Web
            browser for display enhancement purposes.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            You may print or download one copy of a reasonable number of pages
            of the Website for your own personal and business purposes as
            provided in the Terms of Use and not for further reproduction,
            publication, or distribution.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            If we provide desktop, mobile, or other applications for download,
            you may download a single copy to your computer or mobile device
            solely for your commercial use and known business purposes, provided
            you agree to be bound by our end user license agreement for such
            applications.
          </li>
        </Typography>
      </ul>
      <Typography mb={2}> You must not:</Typography>
      <ul>
        <Typography mb={2}>
          <li>Modify copies of any materials from this site.</li>
        </Typography>
        <Typography mb={2}>
          <li>
            Use any illustrations, photographs, video or audio sequences, or any
            graphics separately from the accompanying text.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Delete or alter any copyright, trademark, or other proprietary
            rights notices from copies of materials from this site.
          </li>
        </Typography>
      </ul>

      <Typography mb={2}>
        You must not access or use any part of the Website or any services or
        materials available through the Website for any personal purposes.
      </Typography>
      <Typography mb={2}>
        If you print, copy, modify, download, or otherwise use or provide any
        other person with access to any part of the Website in breach of the
        Terms of Use, your right to use the Website will stop immediately and
        you must, at our option, return or destroy any copies of the materials
        you have made. No right, title, or interest in or to the Website or any
        content on the Website is transferred to you, and all rights not
        expressly granted are reserved by the Company. Any use of the Website
        not expressly permitted by these Terms & Conditions is a breach of the
        Terms of Use and may violate copyright, trademark, and other laws.
      </Typography>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Trademarks
      </Typography>
      <Typography mb={2}>
        The Company name, the terms “theguarantors,” “guaranti,” and “securti,”
        the Company logo, and all related names, logos, product and service
        names, designs, and slogans are trademarks of the Company or its
        affiliates or licensors. You must not use such marks without the prior
        written permission of the Company. All other names, logos, product and
        service names, designs, and slogans on this Website are the trademarks
        of their respective owners.
      </Typography>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Prohibited Uses
      </Typography>
      <Typography mb={2}>
        You may use the Website, create an account, and/or access an account
        only for lawful purposes and in accordance with the Terms of Use. You
        agree not to use the Website or account:
      </Typography>
      <ul>
        <Typography mb={2}>
          <li>
            In any way that violates any applicable federal, state, local, or
            international law or regulation (including, without limitation, any
            laws regarding the export of data or software to and from the US or
            other countries).
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            For the purpose of exploiting, harming, or attempting to exploit or
            harm minors in any way by exposing them to inappropriate content,
            asking for personally identifiable information, or otherwise.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            To send, knowingly receive, upload, download, use, or re-use any
            material that does not comply with the Terms of Use, the Privacy
            Policy, Terms of Service, or the Content Standards.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            To transmit, or procure the sending of, any advertising or
            promotional material without our prior written consent, including
            any “junk mail,” “chain letter,” “spam,” or any other similar
            solicitation.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            To impersonate or attempt to impersonate the Company, a Company
            employee, another user, or any other person or entity (including,
            without limitation, by using email addresses or user names
            associated with any of the foregoing).
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            To engage in any other conduct that restricts or inhibits anyone's
            use or enjoyment of the Website, or which, as determined by us, may
            harm the Company or users of the Website, or expose them to
            liability.
          </li>
        </Typography>
      </ul>

      <Typography mb={2}> Additionally, you agree not to:</Typography>
      <ul>
        <Typography mb={2}>
          <li>
            Use the Website and account in any manner that could disable,
            overburden, damage, or impair the site or interfere with any other
            party's use of the Website, including their ability to engage in
            real time activities through the Website
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Use any robot, spider, or other automatic device, process, or means
            to access the Website for any purpose, including monitoring or
            copying any of the material on the Website.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Use any manual process to monitor or copy any of the material on the
            Website, or for any other purpose not expressly authorized in these
            Terms & Conditions, without our prior written consent.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Use any device, software, or routine that interferes with the proper
            working of the Website.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Introduce any viruses, Trojan horses, worms, logic bombs, or other
            material that is malicious or technologically harmful.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Attempt to gain unauthorized access to, interfere with, damage, or
            disrupt any parts of the Website, the server on which the Website is
            stored, or any server, computer, or database connected to the
            Website.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Attack the Website via a denial-of-service attack or a distributed
            denial-of-service attack.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>Attempt to gain unauthorized access to Integrated Data.</li>
        </Typography>
        <Typography mb={2}>
          <li>
            Otherwise attempt to interfere with the proper working of the
            Website.
          </li>
        </Typography>
      </ul>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Monitoring and Enforcement; Termination
      </Typography>
      <Typography mb={2}>
        {" "}
        We have the right to, subject to all applicable laws:
      </Typography>
      <ul>
        <Typography mb={2}>
          <li>
            Remove or refuse to accept any Integrated Data for any or no reason
            in our sole discretion.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Take any action with respect to any Integrated Data that we deem
            necessary or appropriate in our sole discretion, including if we
            believe that such Integrated Data violates the Terms of Use,
            including infringements of any intellectual property right or other
            right of any person or entity, threatens the personal safety of
            individuals, or could create liability for the Company.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Disclose your identity to the extent necessary, or other information
            about you, to government agencies and any third party who provides
            verified claims that material delivered by you violates their
            rights, including their intellectual property rights or their right
            to privacy.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Take appropriate legal action, including without limitation,
            referral to law enforcement, for any illegal or unauthorized use of
            the Website.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Terminate or suspend your access to all or part of the Website for
            any or no reason, including without limitation, any violation of the
            Terms of Use.
          </li>
        </Typography>
      </ul>

      <Typography mb={2}>
        Without limiting the foregoing, we have the right to cooperate fully
        with any law enforcement authorities or court order requesting or
        directing us to disclose the identity or other information of anyone
        posting any materials on or through the Website. YOU WAIVE AND HOLD
        HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE
        PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE
        COMPANY/ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE
        OF, INVESTIGATIONS BY EITHER THE COMPANY/SUCH PARTIES OR LAW ENFORCEMENT
        AUTHORITIES.
      </Typography>
      <Typography mb={2}>
        However, we do not undertake to review all material before it is
        provided to Company and cannot ensure prompt removal of inaccurate
        information. Accordingly, we assume no liability for any action or
        inaction regarding transmissions, communications, Integrated Data, or
        content provided by you. We have no liability or responsibility to
        anyone for performance or nonperformance of the activities described in
        this section.
      </Typography>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Content Standards
      </Typography>
      <Typography mb={2}>
        These content standards apply to any and all information provided by you
        and use of Interactive Services. All information provided by you to
        Company must in its entirety comply with all applicable federal, state,
        local, and international laws and regulations. Without limiting the
        foregoing, any information provided by you must not:
      </Typography>
      <ul>
        <Typography mb={2}>
          <li>
            Contain any material that is defamatory, obscene, indecent, abusive,
            offensive, harassing, violent, hateful, inflammatory, or otherwise
            objectionable.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Infringe any patent, trademark, trade secret, copyright, or other
            intellectual property or other rights of any other person.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Violate the legal rights (including the rights of publicity and
            privacy) of others or contain any material that could give rise to
            any civil or criminal liability under applicable laws or regulations
            or that otherwise may be in conflict with the Terms of Use and our
            Privacy Policy{" "}
            <ExternalLink text="https://www.theguarantors.com/privacy" />.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>Be likely to deceive any person.</li>
        </Typography>
        <Typography mb={2}>
          <li>
            Promote any illegal activity, or advocate, promote, or assist any
            unlawful act.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Cause annoyance, inconvenience, or needless anxiety or be likely to
            upset, embarrass, alarm, or annoy any other person.
          </li>
        </Typography>
        <Typography mb={2}>
          <li>
            Impersonate any person, or misrepresent your identity or affiliation
            with any person or organization.
          </li>
        </Typography>
      </ul>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Reliance on Information Posted
      </Typography>
      <Typography mb={2}>
        The information presented on or through the Website or your account is
        made available solely for general information purposes. We do not
        warrant the accuracy, completeness, or usefulness of this information.
        Any reliance you place on such information is strictly at your own risk.
        We disclaim all liability and responsibility arising from any reliance
        placed on such materials by you or any other visitor to the Website, or
        by anyone who may be informed of any of its contents.
      </Typography>
      <Typography mb={2}>
        This Website and the Interactive Services may include/includes content
        provided by third parties, including materials provided by others. All
        statements and/or opinions expressed in these materials, and all
        articles and responses to questions and other content, other than the
        content provided by the Company, are solely the opinions and the
        responsibility of the person or entity providing those materials. These
        materials do not necessarily reflect the opinion of the Company. We are
        not responsible, or liable to you or any third party, for the content or
        accuracy of any materials provided by any third parties.
      </Typography>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Changes to the Website
      </Typography>
      <Typography mb={2}>
        We may update the content on this Website from time to time, but its
        content is not necessarily complete or up-to-date. Any of the material
        on the Website may be out of date at any given time, and we are under no
        obligation to update such material.
      </Typography>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Links from the Website
      </Typography>
      <Typography mb={2}>
        If the Website contains links to other sites and resources provided by
        third parties, these links are provided for your convenience only. This
        includes links contained in advertisements, including banner
        advertisements and sponsored links. We have no control over the contents
        of those sites or resources, and accept no responsibility for them or
        for any loss or damage that may arise from your use of them. If you
        decide to access any of the third-party websites linked to this Website,
        you do so entirely at your own risk and subject to the terms and
        conditions of use for such websites.
      </Typography>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Geographic Restrictions
      </Typography>
      <Typography mb={2}>
        The owner of the Website is based in the State of Delaware in the United
        States with a headquarters in the State of New York. We provide this
        Website and account creation and access services for persons located in
        the United States. We make no claims that the Website or any of its
        content is accessible or appropriate outside of the United States.
        Access to the Website may not be legal by certain persons or in certain
        countries. If you access the Website from outside the United States, you
        do so on your own initiative and are responsible for compliance with
        local laws.
      </Typography>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Disclaimer of Warranties
      </Typography>
      <Typography mb={2}>
        You understand that we cannot and do not guarantee or warrant that files
        available for downloading from the internet or the Website will be free
        of viruses or other destructive code. You are responsible for
        implementing sufficient procedures and checkpoints to satisfy your
        particular requirements for anti-virus protection and accuracy of data
        input and output, and for maintaining a means external to our site for
        any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY
        LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
        DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY
        HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER
        PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE
        WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR
        DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO
        IT.
      </Typography>
      <Typography mb={2}>
        YOUR USE OF THE WEBSITE, THE CREATION OF AN ACCOUNT, ACCESSING YOUR
        ACCOUNT, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
        WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES
        OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS
        AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
        IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY
        MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
        SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
        WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
        ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS
        CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE
        ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
        CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE
        OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY
        SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR
        NEEDS OR EXPECTATIONS.
      </Typography>
      <Typography mb={2}>
        TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL
        WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
        OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
        MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
      </Typography>
      <Typography mb={2}>
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </Typography>
      <Typography mb={2}> Limitation on Liability</Typography>
      <Typography mb={2}>
        TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS
        AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
        OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY
        LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
        INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON
        THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT,
        SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT
        NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
        LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
        SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED
        BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN
        IF FORESEEABLE.
      </Typography>
      <Typography mb={2}>
        THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </Typography>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Indemnification
      </Typography>
      <Typography mb={2}>
        You agree to defend, indemnify, and hold harmless the Company, its
        affiliates, licensors, and service providers, and its and their
        respective officers, directors, employees, contractors, agents,
        licensors, suppliers, successors, and assigns from and against any
        claims, liabilities, damages, judgments, awards, losses, costs,
        expenses, or fees (including reasonable attorneys' fees) arising out of
        or relating to your violation of the Terms & Conditions or your use of
        the Website, including, but not limited to, your Integrated Data and
        User Contributions, any use of the Website's content, services, and
        products other than as expressly authorized in the Terms of Use, or your
        use of any information obtained from the Website.
      </Typography>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Governing Law and Jurisdiction
      </Typography>
      <Typography mb={2}>
        All matters relating to the Website, account creation or access and the
        Terms & Conditions, and any dispute or claim arising therefrom or
        related thereto (in each case, including non-contractual disputes or
        claims), shall be governed by and construed in accordance with the
        internal laws of the State of New York without giving effect to any
        choice or conflict of law provision.
      </Typography>
      <Typography mb={2}>
        Any legal suit, action, or proceeding arising out of, or related to,
        these Terms & Conditions or the Website shall be instituted exclusively
        in the federal courts of the United States or the courts of the State of
        New York, in each case located in the County of New York, although we
        retain the right to bring any suit, action, or proceeding against you
        for breach of these Terms & Conditions in your country of residence or
        any other relevant country. You waive any and all objections to the
        exercise of jurisdiction over you by such courts and to venue in such
        courts.
      </Typography>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Arbitration
      </Typography>
      <Typography mb={2}>
        At Company's sole discretion, it may require You to submit any disputes
        arising from these Terms & Conditions or use of the Website, including
        disputes arising from or concerning their interpretation, violation,
        invalidity, non-performance, or termination, to final and binding
        arbitration. Company retains the right to select the arbitration
        services of its choice.
      </Typography>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Limitation on Time to File Claims
      </Typography>
      <Typography mb={2}>
        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
        THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR
        AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR
        CLAIM IS PERMANENTLY BARRED.
      </Typography>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Waiver and Severability
      </Typography>
      <Typography mb={2}>
        No waiver by the Company of any term or condition set out in these Terms
        & Conditions shall be deemed a further or continuing waiver of such term
        or condition or a waiver of any other term or condition, and any failure
        of the Company to assert a right or provision under these Terms &
        Conditions shall not constitute a waiver of such right or provision.
      </Typography>
      <Typography mb={2}>
        If any provision of these Terms & Conditions is held by a court or other
        tribunal of competent jurisdiction to be invalid, illegal, or
        unenforceable for any reason, such provision shall be eliminated or
        limited to the minimum extent such that the remaining provisions of the
        Terms & Conditions will continue in full force and effect.
      </Typography>
      <Typography
        mb={2}
        variant="subtitle2"
        sx={{ textDecoration: "underline" }}
      >
        Entire Agreement
      </Typography>
      <Typography mb={2}>
        The Terms of Use and all subsequent binding and enforceable contracts
        between you and the Company constitute the sole and entire agreement
        between you and GuarantR, Inc. In the event of inconsistencies between
        these Terms & Conditions and any other agreement or the Privacy Policy
        or the Terms of Services, the other document shall govern in lieu of
        these Terms & Conditions.
      </Typography>
    </div>
  );
};

export default Terms;
