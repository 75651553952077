import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { routes } from "../../constants/Constants";
import { InitOktaMsaClient } from "./OktaClient";
import Loader from "../../components/Loader";

export default function HandleOktaMsaSignIn() {
  const oktaAuth = InitOktaMsaClient();

  const login = async () => oktaAuth.signInWithRedirect();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const initOktaAuth = async () => {
      if (isAuthenticated) return;
      setIsAuthenticated(await oktaAuth.isAuthenticated());
    };

    initOktaAuth();
  }, [oktaAuth, setIsAuthenticated, isAuthenticated]);

  if (isAuthenticated) {
    navigate(routes.oktaMsaToken);
  } else {
    login();
  }

  return <Loader />;
}
